import React from 'react';
import './ResultsPage.css'; // Import CSS file for styling
import { useLocation } from 'react-router-dom';

const ResultsPage = () => {
  const location = useLocation();
  const results = location.state.results;

  if (!results || !results.data) {
    return <div>Loading...</div>; // or some other placeholder
  }

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="results-container">
      <h1>Results</h1>
      <button onClick={handlePrint}>Print</button>
      <table>
        <tbody>
          <tr>
            <th>Total number of sensitive words:</th>
            <td>{results.totalSensitiveWords}</td>
          </tr>
          <tr>
            <th>Report generated on:</th>
            <td>{results.reportDate}</td>
          </tr>
          <tr>
            <th>Version of the sensitivity checker used:</th>
            <td>{results.checkerVersion}</td>
          </tr>
          {/* <tr>
            <th>Cumulative summary for the month/year:</th>
            <td>{results.cumulativeSummary}</td>
          </tr>
          <tr>
            <th>Most frequently flagged words:</th>
            <td>{results.mostFrequentWords}</td>
          </tr> */}
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Text</th>
            <th>Matches</th>
            <th>Match Count</th>
            <th>Location</th>
            <th>Context</th>
            <th>Suggested Change</th>
            <th>Rephrased Sentence</th>
            <th>Severity Level</th>
          </tr>
        </thead>
        <tbody>
          {results.data.map((result, index) => (
            <tr key={index}>
              <td>{result.text}</td>
              <td>{result.matches}</td>
              <td>{result.matchCount}</td>
              <td>{result.location}</td>
              <td>{result.context}</td>
              <td>{result.suggestedChange}</td>
              <td>{result.rephrasedSentence}</td>
              <td>{result.severityLevel}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResultsPage;