import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TextEntryPage.css'; 
import ResultsPage from './Results.js';
import logo from './logo.png'; // Import your logo image
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

const TextEntryPage = () => {
  const [textInput, setTextInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const baseURl = 'https://testapi.learnafrica.ng/api/v1';

  const handleTextInputChange = (event) => {
    setTextInput(event.target.value);
  };

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(baseURl + '/checker/analyze-text-beta', { text: textInput });
      setResults([response.data]);
      navigate('/results', { state: { results: response.data } }); // Navigate to the results page
    } catch (error) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Learn Africa Sensitivity Checker"; // Set the page title
  }, []);

  return (
    <div className="text-entry-container">
      <header>
        <img src={logo} alt="Logo" className="logo left-aligned" />        
        <h2>Learn Africa Sensitivity Checker</h2>
      </header>
      <textarea value={textInput} onChange={handleTextInputChange} className="text-input" placeholder='Paste text here...' />
      <button onClick={handleSubmit} disabled={loading} className="submit-button">
        {loading ? 'Loading...' : 'Start Check'}
      </button>
      {results.length > 0 && <ResultsPage results={results} />}
      <footer>
        <p>2024 &copy; Learn Africa Plc</p>
      </footer>
    </div>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<TextEntryPage />} />
        <Route path="/results" element={<ResultsPage />} />
      </Routes>
    </Router>
  );
}

export default App;